/** @format */

import { DnNavbar } from '@denali-design/react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import React, { useState } from 'react';

import { NavbarLinks } from './link/navbarLinks';
import Login from './login/login';
// region SVGs
// @ts-expect-error ReactComponent isn't exposed by gatsby-plugin-svgr
import { ReactComponent as Logo } from './svg/ySender-Hub.svg';
// endregion

export const Navbar: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <header>
            <DnNavbar>
                <DnNavbar.Left>
                    <Login mobile={true} />
                    {/* Logo */}
                    <div className={'flex align-items-center'}>
                        {/* Mobile hamburger menu */}
                        <div className={'float-left hide-small-desktop-up'}>
                            <a className={'nav-item nav-item-hamburger'}>
                                <i
                                    className={'d-icon d-menu-hamburger'}
                                    onClick={() => {
                                        return setIsOpen(!isOpen);
                                    }}
                                />
                            </a>
                        </div>

                        <div className={'nav-brand'}>
                            <Link to={'/'}>
                                <Logo />
                            </Link>
                        </div>
                    </div>
                </DnNavbar.Left>
                <div className={classNames('nav-responsive', { 'is-active': isOpen })}>
                    {/* Center navigation links */}
                    <DnNavbar.Center>
                        <NavbarLinks setNavbarOpen={setIsOpen} />
                    </DnNavbar.Center>
                    <DnNavbar.Right>
                        <Login />
                    </DnNavbar.Right>
                </div>
            </DnNavbar>
        </header>
    );
};
