/** @format */

import React, { createContext, useEffect, useState } from 'react';
import { Endpoints, Header, Type } from 'sender-hub-shared';

import type { FunctionComponent, PropsWithChildren, ReactElement } from 'react';
import type { ResponseHeaders } from 'sender-hub-shared';

export const PrivacyJurisdictionContext = createContext<ResponseHeaders | null>({
    [Header.X_JURISDICTION_TYPE]: Type.WORLD,
    [Header.X_ACOOKIE_FIELDS]: null
});

export const PrivacyJurisdiction: FunctionComponent<PropsWithChildren> = ({
    children
}: PropsWithChildren): ReactElement => {
    const [privacyJurisdictionHeaders, setPrivacyJurisdictionHeaders] = useState<ResponseHeaders | null>(null);

    useEffect(() => {
        async function getPrivacyJurisdictionHeaders(): Promise<ResponseHeaders | undefined> {
            return await fetch(Endpoints.PRIVACY_JURISDICTION + Endpoints.PRIVACY_HEADERS)
                .then(async (resp) => {
                    return (await resp.json()) as ResponseHeaders;
                })
                .catch(() => {
                    if (process.env.GATSBY_LOCAL_DEV) {
                        // NOTE: If you are developing locally and not running the express server you can tweak the values returned here.
                        return {
                            [Header.X_JURISDICTION_TYPE]: Type.CCPA,
                            [Header.X_ACOOKIE_FIELDS]: null
                        };
                    }
                });
        }

        getPrivacyJurisdictionHeaders()
            .then((headers) => {
                if (privacyJurisdictionHeaders === null && headers) {
                    setPrivacyJurisdictionHeaders(headers);
                }
            })
            .catch(() => {
                // Wasn't able to get privacy headers, so we won't do anything here for now
            });
    }, [privacyJurisdictionHeaders]);

    return (
        <PrivacyJurisdictionContext.Provider value={privacyJurisdictionHeaders}>
            {children}
        </PrivacyJurisdictionContext.Provider>
    );
};
